<template>
  <div class="card flex flex-column mt-1 mb-1 ml-2 p-2 bg-lightNeoGray">
      <div class="flex flex-row">
          <div class="flex flex-column mr-2 p-0">
              <div :class="`card pb-0 m-0 ${showPointer1}`" @click="showChart('value1')">
                  <div class="social-icon">
                      <span :class="`text-4xl ${colorVal_1}`">{{ value_1 }}</span>
                  </div>
                  <span :class="`text-xs ${colorTextLabel}`">{{ valLabel_1 }}</span>
              </div>
          </div>
          <div class="flex flex-column mr-0 p-0">
              <div :class="`card pb-0 m-0 ${showPointer2}`" @click="showChart('value2')">
                  <div class="social-icon">
                      <span :class="`text-4xl ${colorVal_2}`">{{ value_2 }}</span>
                  </div>
                  <span :class="`text-xs ${colorTextLabel}`">{{ valLabel_2 }}</span>
              </div>
          </div>
      </div>
      <div class="flex flex-row justify-content-center mt-1">
          <div class="flex align-items-center justify-content-center">
            <span v-if="widgetLabel" :class="`mr-1 text-sm ${colorTextLabel}`">{{ widgetLabel }}</span>
            <span :class="`text-sm ${colorTextLabel}`">{{ value_1_unit }}</span>
          </div>
      </div>
  </div>
  <dialog-chart-single :icon="iconSymbol" :node="value1" v-bind:show="showChart1" @chart-close="showChart1 = false"></dialog-chart-single>
  <dialog-chart-single :icon="iconSymbol" :node="value2" v-bind:show="showChart2" @chart-close="showChart2 = false"></dialog-chart-single>
</template>

<script>
import { defineComponent } from "vue";
import dialogChartSingle from '@/components/dialog/chartSingle.vue';

export default defineComponent({
  name: "widgetSetpoint",
  setup() {},
  components: {
    dialogChartSingle,
  },
  props: {
    label: {
      type: String,
      required: false
    },
    label1: {
      type: String,
      required: false
    },
    label2: {
      type: String,
      required: false
    },
    value1: {
      type: Object,
      required: true
    },
    value2: {
      type: Object,
      required: true
    },
    icon: {
      type: String,
      required: false
    },
    colorVal1: {
      type: String,
      required: false
    },
    colorVal2: {
      type: String,
      required: false
    },
    zeroPoint: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      loading: false,
      showChart1: false,
      showChart2: false,
    }
  },
  watch: {

  },
  computed: {
    widgetLabel() {
      if (this.label !== undefined) return this.label;
      return false;
    },
    valLabel_1() {
      if (this.label1 !== undefined) return this.label1;
      return 'IST';
    },
    valLabel_2() {
      if (this.label2 !== undefined) return this.label2;
      return 'SOLL';
    },
    iconSymbol() {
      if (this.icon !== undefined) return this.icon;
      else return '';
    },
    colorVal_1() {
      if (this.colorVal1 !== undefined) return this.colorVal1;
      else return `fg-lime`;
    },
    colorVal_2() {
      if (this.colorVal2 !== undefined) return this.colorVal2;
      else return `fg-lime`;
    },
    colorTextLabel() {
      return `fg-gray`;
    },
    value_1() {
      if (this.value1.value !== undefined) return this.value1.value;
      else return "";
    },
    value_1_unit() {
      if (this.value1.unit !== undefined) return this.value1.unit;
      else return "";
    },
    value_2() {
      if (this.value2.value !== undefined && this.zeroPoint && (this.value2.value === "0" || this.value2.value === "0.0")) return "-.-";
      else if (this.value2.value !== undefined) return this.value2.value;
      else return "";
    },
    value_2_unit() {
      if (this.value2.unit !== undefined) return this.value2.unit;
      else return "";
    },
    isRecord1() {
      if (typeof(this.value1) === "object") {
        if (this.value1.record === "true") return true;
      }
      return false;
    },
    showPointer1() {
      if (this.isRecord1) return "cursor-pointer";
      else return "";
    },
    isRecord2() {
      if (typeof(this.value2) === "object") {
        if (this.value2.record === "true") return true;
      }
      return false;
    },
    showPointer2() {
      if (this.isRecord2) return "cursor-pointer";
      else return "";
    }
  },
  mounted() {
  },
  methods: {
    showChart(value) {
      if (value === 'value1' && this.isRecord1) this.showChart1 = true;
      if (value === 'value2' && this.isRecord2) this.showChart2 = true;
    },
  },
});
</script>

<style lang="scss" scoped>
</style>