<template>
    <div v-if="loading">
        <loader-skeleton></loader-skeleton>
    </div>
    <Dialog 
        v-if="!loading"
        v-model:visible="entryDialog"
        :header="'Anzeigewert bearbeiten [' + ioDialogData.i + ']'"
        :modal="true"
        class="p-fluid"
        @hide="hideDialog"
        :style="{ width: '40vw' }"
        :breakpoints="{ '960px': '85vw', '641px': '100vw' }"
        >
        <div class="field">
            <p>Wird das Feld <b>Label</b> nicht ausgefüllt, wird der zugehörige IO-Name automatisch angezeigt</p>
        </div>
        <div class="field">
            <label for="name">Name</label>
            <InputText id="name" v-model="ioDialogData.name" required="false" autofocus />
        </div>
        <div class="field">
            <label for="label">Label</label>
            <InputText id="label" v-model="ioDialogData.label" required="false" autofocus />
        </div>

        <div class="formgrid grid">
            <div class="field col">
                <label for="type">Feldbreite</label>
                <InputNumber id="width" v-model="ioDialogData.width" mode="decimal" showButtons :min="1" :max="12" />
            </div>

            <div class="field col">
                <label for="background">Hintergrund</label>
                <Dropdown id="style.background" v-model="ioDialogData.style.background" :options="colors" optionLabel="label" optionValue="value">
                    <template #value="slotProps">
                        <div :class="'bg-' + slotProps.value">
                            <span>Hintergrund</span>
                        </div>
                    </template>
                    <template #option="slotProps">
                        <div :class="'bg-' + slotProps.option.value">
                            <span>{{ slotProps.option.label }}</span>
                        </div>
                    </template>
                </Dropdown>
            </div>

            <div class="field col">
                <label for="foreground">Vordergrund</label>
                <Dropdown id="style.foreground" v-model="ioDialogData.style.foreground" :options="colors" optionLabel="label" optionValue="value">
                    <template #value="slotProps">
                        <div :class="'fg-' + slotProps.value">
                            <span>Vordergrund</span>
                        </div>
                    </template>
                    <template #option="slotProps">
                        <div :class="'fg-' + slotProps.option.value">
                            <span>{{ slotProps.option.label }}</span>
                        </div>
                    </template>
                </Dropdown>
            </div>
        </div>

        <div class="field">
            <label for="pattern">Muster</label>
            <Dropdown id="style.pattern" v-model="ioDialogData.style.pattern" :options="patterns" optionLabel="label" optionValue="value">
                <template #value="slotProps">
                    <div :class="'bg-white fg-black ptrn-' + slotProps.value">
                        <span>Muster</span>
                    </div>
                </template>
                <template #option="slotProps">
                    <div :class="'bg-white fg-black ptrn-' + slotProps.option.value">
                        <span>{{ slotProps.option.label }}</span>
                    </div>
                </template>
            </Dropdown>
        </div>

        <div class="field">
            <label for="data.id">Auswahl des Moduls</label>
            <Tree 
                :value="getModules"
                :loading="loading"
                selectionMode="single"
                v-model:selectionKeys="selectedKey"
                :expandedKeys="expandedKeys"
                :filter="true" filterMode="strict"
                filterPlaceholder="Wert suchen..."
                @node-select="onNodeSelect">
                
                <template #default="slotProps">
                    <div class="flex align-items-center">
                        <div><span class="fg-lightBlue">{{ slotProps.node.moduleName }}</span><span class="fg-lightCobalt"> -> </span>{{ slotProps.node.metadata.label }} <span class="text-small">[Anlage {{ slotProps.node.metadata.department }} : {{ slotProps.node.metadata.category }}]</span></div>
                    </div>
                </template>
            </Tree>
        </div>

        <div class="field">
            <label>ID</label>
            <InputText id="_id" v-model="ioDialogData.key" readonly />
        </div>

        <template #footer>
            <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
            <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveDialog" />
        </template>
    </Dialog>
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import store from '@/store';

export default defineComponent({
    name: 'dialogIoSingle',
    setup() {},
    emits: ['entrySave', 'entryAbort'],
    props: {
        showDialog: {
            type: Boolean,
            required: true,
        },
        dialogData: {
            type: Object,
            required: true,
        },
        dialogObj: {
            type: Object,
            required: false,
        },
    },
    mounted() {
        this.loading = true;
        this.ioDialogData = this.dialogData;
        this.entryDialog = this.entryDiag;
        // for (let node of this.nodes) {
        //     if (node.key === this.ioObj.metadata.category) {
        //         this.expandNode(node);
        //     }
        // }
        this.loadModules(true).then((response) => {
            console.log(response);
            console.log(`[ENATDIAG] : component ready!`);
            this.loading = false;
        });
        console.log(this.ioDialogData);
    },
    beforeUnmount() {
        this.resetDialogData();
    },
    computed: {
        ...mapGetters({
            getNodeWidget: 'getNodeWidget',
            isLoading: 'isLoading',
            getDepartment: 'getDepartment',
            getIoTree: 'getIoTree',
            getStyleColors: 'getStyleColors',
            getStylePatterns: 'getStylePatterns',
            getModules: 'opcua/getModules',
            getModule: 'opcua/getModule',
            getNode: 'opcua/getNode',
        }),
        // ioDialogData: function () {
        //   return this.dialogData;
        // },
        ioObj: function () {
            return this.dialogObj;
        },
        entryDiag: function () {
            return this.showDialog;
        },
        // ioDialogData: function () {
        //   return this.dialogData;
        // },
        nodeEntry: function () {
            if (this.content.data.id) {
                return store.getters.getValue(this.content.data.id);
            } else return undefined;
        },
        nodes: function () {
            return store.getters.getIoTree;
        },
        colors: function () {
            return store.getters.getStyleColors;
        },
        patterns: function () {
            return store.getters.getStylePatterns;
        },
    },
    data() {
        return {
            loading: true,
            entryDialog: false,
            ioDialogData: {},
            // ioObj: {},
            submitted: false,
            selectedKey: {},
            expandedKeys: {},
        };
    },
    methods: {
        ...mapActions({
            loadModules: 'opcua/loadModules',
        }),
        hideDialog() {
            this.resetDialogData();
            this.$emit('entryAbort');
        },
        resetDialogData() {
            this.ioDialogData = {
                style: {},
                components: {},
                data: {},
            };
            this.selectedKey = {};
            this.expandedKeys = {};
        },
        expandNode(node) {
            this.expandedKeys[node.key] = true;
            if (node.children && node.children.length) {
                for (let child of node.children) {
                    this.expandNode(child);
                    if (child.key === this.ioObj._id) {
                        this.selectedKey[this.ioObj._id] = true;
                    }
                }
            }
        },
        saveDialog() {
            this.submitted = true;
            console.log(this.ioDialogData);

            if (this.ioDialogData.i) {
                // let _index = this.findIndexById(this.ioDialogData._id);
                // if (_index >= 0) {
                //   this.system_user[_index] = this.ioDialogData;
                //   // SocketioService.setUserList(this.system_user[_index]);
                // } else {
                //   // SocketioService.setUserList(this.ioDialogData);
                // }
                // this.content = {...this.ioDialogData};
                this.$emit('entrySave', this.ioDialogData);
                this.entryDialog = false;
                this.resetDialogData();
            }
        },
        onNodeSelect(node) {
            console.log(">>> SELECTED NODE:", node);
            // const newObj = store.getters.getValue(node.key);
            this.selectedKey = node.key;
            // this.ioDialogData.data.id = node.key;
            this.ioDialogData.data.id = node.key;
            this.ioDialogData.data.module = node.moduleName;

            this.$toast.add({
                severity: 'success',
                summary: 'Modul ausgewählt',
                detail: `Modul: ${node.moduleName} | Bezeichnung: ${node.metadata.label} | ID: ${this.selectedKey}`,
                life: 3000,
            });
        },
    },
});
</script>
