<template>
  <Dialog v-model:visible="entryDialog" :closable="false" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '450px' }" :modal="true" class="p-fluid">
    <template #header>
      <p class="nice-font"><i class="mif-wrench"></i><span class="pl-1 mt-1">Fast MultiChange</span><span class="pl-1 mt-1 fg-lightPink">⚡ {{ paramLabel }}</span></p>
    </template>
    <div @keydown.esc="hideMultiChange" @keydown.enter="saveMultiChange">
      <Panel>
        <div class="flex pb-3 justify-content-center">
          <Avatar v-for="param in selectedParameters" :key="param.identifier" :item="param" :label="param.department" v-tooltip="`${param.moduleLabel} - ${param.moduleDescription}`" class="mr-1 font-semibold bg-lightLime fg-crimson cursor-pointer" />
        </div>
        <div class="grid p-fluid">
          <div class="col-12 pl-5 pr-5">
            <div v-if="changingParameters[0].unit === '#BOOL'" class="text-center mt-2 mb-2">
              <InputSwitch v-model="changingParameters[0].value" /><span class="ml-2">Ein/Aus</span>
            </div>
            <div v-else-if="changingParameters[0].unit === '#HMS'" class="p-inputgroup">
              <InputMask id="changingValueField" ref="changingValueField" mask="99:99:99" placeholder=""
                slotChar="hh:mm:ss" v-model="changingParameters[0].value" autofocus @focus="focusElement"
                class="nice-font inputtext-param text-center text-5xl" />
                <span class="p-inputgroup-addon nice-font text-3xl">{{ changingParameters[0].unitStr }}</span>
            </div>
            <div v-else class="p-inputgroup">
              <InputNumber id="changingValueField" ref="changingValueField"
                v-model="changingParameters[0].value" autofocus @focus="focusElement"
                :minFractionDigits="1" mode="decimal" locale="de-DE" :useGrouping="false"
                inputClass="nice-font inputtext-param text-center text-5xl"  />
              <!-- <InputText v-model="changingValue" :ref="'e' + 'param.index'" class="nice-font inputtext-param text-center text-5xl"
              @focus="focusField('e' + 'param.index')" @blur="blurField" /> -->
              <span class="p-inputgroup-addon nice-font text-3xl">{{ changingParameters[0].unitStr }}</span>
            </div>
          </div>
        </div>
      </Panel>
    </div>
    <template #footer>
      <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideMultiChange" />
      <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveMultiChange" />
    </template>
  </Dialog>
</template>

<script>
import { defineComponent } from 'vue';
import SocketioService from "@/services/socketioService.js";
import helpers from "@/helpers";
import { parseNode, setOpcValueToast } from "@/helpers";

export default defineComponent({
    name: 'dialogMultiChange',
    setup() {},
    emits: ['entrySave', 'entryAbort'],
    props: {
        showDialog: {
            type: Boolean,
            required: true,
        },
        node: {
            type: Array,
            required: true,
        },
    },
    mounted() {
        this.newName = this.nameToChange;
        this.dialogState = this.showDialog;
    },
    computed: {
        entryDialog: function () {
            return this.dialogState;
        },
        paramLabel: function () {
            if (this.node[0] !== undefined && this.node[0].description) {
                return this.node[0].description;
            } else {
                return this.$store.getters.getNoval;
            }
        },
    },
    data() {
        return {
            dialogState: false,
            newName: null,
            diagMultiChange: false,
            selectedParameters: [],
            changingParameters: [],
        };
    },
    watch: {
        showDialog() {
            this.multiParamChange();
            this.dialogState = this.showDialog;
        }
    },
    methods: {
        focusElement(event) {
            if (event !== undefined) {
                event.target.select();
            }
        },
        hideDialog() {
            this.dialogState = false;
            this.$emit('entryAbort');
        },
        resetDialogData() {
            this.newName = null;
        },
        saveDialog() {
            if (this.newName !== null && this.newName.length > 0) {
                this.$emit('entrySave', this.newName);
                // this.entryDialog = false;
                this.resetDialogData();
            }
        },
        multiParamChange() {
            this.selectedParameters = [ ...this.node];
            // console.log(this.selectedParameters);
            const _obj = JSON.parse(JSON.stringify(this.node));
            console.log(_obj);
            if (Array.isArray(_obj) && _obj.length > 0) {
                if (_obj[0].writeable === "true") {
                this.changingParameters = _obj;
                this.changingValue = helpers.paramValue(this.changingParameters[0], 'value');
                this.changingUnit = helpers.paramValue(this.changingParameters[0], 'unit');
                this.diagMultiChange = true;
                } else {
                this.$toast.add({ severity: 'warn', summary: 'Wert(e) nicht änderbar!', detail: 'Es handelt sich um keinen Einstellwert.', life: 3000 });
                }
            }
        },
        hideMultiChange() {
            this.selectedParameters = [];
            this.hideDialog();
        },
        saveMultiChange() {
        if (this.selectedParameters.length > 0 && this.changingParameters.length > 0) {
            const saveArray = [];
            let tempArray = JSON.parse(JSON.stringify(this.selectedParameters));
            if (Array.isArray(tempArray) && tempArray.length > 0) {
            tempArray.forEach((entry) => {
                if (entry.value !== this.changingParameters[0].value) {
                entry.value = this.changingParameters[0].value;
                saveArray.push(parseNode(entry, true));
                }
            });
            if (saveArray.length > 0) {
                SocketioService.setOpcValue(saveArray, (err, response) => {
                if (err) {
                    console.log(err);
                } else {
                    console.log(response);
                    setOpcValueToast(response, this.$root);
                    this.selectedParameters = [];
                    this.hideDialog();
                }
                });
            } else {
                setOpcValueToast(null, this.$root);
                this.selectedParameters = [];
                this.hideDialog();
            }
            }
        } else {
            this.$toast.add({
                severity: 'warn',
                summary: 'Keine Änderungen!',
                detail: 'Es wurde kein einziger Wert geändert!',
                life: 3000,
            });
            this.selectedParameters = [];
            this.diagMultiChange = false;
        }
        },
    },
});
</script>
