<template>
  <div :class="`flex flex-column mr-2 ${showPointer}`" @click="showChart('value1')">
    <Knob v-model="valueProgress" :size="60" :min="minScale" :max="maxScale" valueTemplate="{value}" style="margin-bottom:-10px;" readonly />
    <span :class="`text-xs ${colorTextLabel}`">{{ valueLabel }} {{ valueUnit }}</span>
  </div>
  <dialog-chart-single :icon="value.icon" :node="value" v-bind:show="showChart1" @chart-close="showChart1 = false"></dialog-chart-single>
</template>

<script>
import { defineComponent } from "vue";
import dialogChartSingle from '@/components/dialog/chartSingle.vue';

export default defineComponent({
  name: "widgetMiniProgress",
  setup() {},
  components: {
    dialogChartSingle,
  },
  props: {
    label: {
      type: String,
      required: false
    },
    value: {
      type: Object,
      required: true
    },
    min: {
      type: Number,
      required: false
    },
    max: {
      type: Number,
      required: false
    },
    colorText: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      loading: false,
      showChart1: false,
    }
  },
  watch: {

  },
  computed: {
    valueLabel: function () {
      if (this.label !== undefined) return this.label;
      if (this.value.label !== undefined) return this.value.label;
      return '';
    },
    colorTextLabel() {
      if (this.colorText !== undefined) return this.colorText;
      else return `fg-gray`;
    },
    valueProgress: function () {
      if (this.value.value !== undefined) return Number(this.value.value);
      return 0;
    },
    valueUnit: function () {
      if (typeof(this.value) === "object") {
        if (this.value.unit.includes("#")) return '';
        return this.value.unitStr;
      }
      return '';
    },
    minScale() {
      if (this.min !== undefined) return Number(this.min);
      else return 0;
    },
    maxScale() {
      if (this.max !== undefined) return Number(this.max);
      else return 100;
    },
    isRecord() {
      if (typeof(this.value) === "object") {
        if (this.value.record === "true") return true;
      }
      return false;
    },
    showPointer() {
      if (this.isRecord) return "cursor-pointer";
      else return "";
    }
  },
  mounted() {
  },
  methods: {
    showChart(value) {
      if (this.isRecord) {
        if (value === 'value1') this.showChart1 = true;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
</style>