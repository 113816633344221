<template>
  <div class="info flex flex-column">
      <div class="info flex flex-column fg-lime">
          <transition name="valchange" mode="out-in">
              <!-- <h1 :key="value1" class="inline-block mb-0 mt-0 cursor-pointer" @click="$emit('showChart', $event)">   -->
              <h1 :key="value1" :class="`inline-block mb-0 mt-0 ${showPointer}`" @click="showChart('value1')">
                  {{ value1 }}<span class="text-sm" style="vertical-align: super">{{ unit1 }}</span>
              </h1>
          </transition>
      </div>
      <div class="flex flex-row">
          <div class="flex flex-column justify-content-center">
              <span :class="fgColor1" class="subtext mt-1">{{ departmentLabel }} ⚡ {{ moduleData.metadata.label }}</span>
              <span :class="fgColor1" class="subtext mt-1">{{ moduleData.metadata.description }}</span>
          </div>
          <div class="flex flex-column justify-content-center align-items-center p-1 ml-1">
              <!-- <div class="flex align-items-center justify-content-center card bg-bespin shadow-3 w-max h-full p-1" v-tooltip="departmentLabel"> -->
              <div class="input-container" v-tooltip="`Abteilung <b>${departmentLabel}</b>`">
                <i class="icon-inline" :class="icon1"></i>
                <h4 class="title">{{ departmentKey }}</h4>
              </div> 
          </div>
      </div>
  </div>
  <dialog-chart-single :icon="value.icon" :node="value" v-bind:show="showChart1" @chart-close="showChart1 = false"></dialog-chart-single>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from 'vuex';
import store from '@/store';
import dialogChartSingle from '@/components/dialog/chartSingle.vue';
// import widgetGuiValue from '@/components/fragments/widgetGuiValue.vue';

export default defineComponent({
  name: "widgetHeader",
  emits: ['showChart'],
  setup() {},
  components: {
    // widgetGuiValue,
    dialogChartSingle,
  },
  props: {
    value: {
      type: null,
      required: true
    },
    moduleData: {
      type: Object,
      required: true
    },
    fgColor: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      loading: false,
      showChart1: false,
    }
  },
  watch: {

  },
  computed: {
    ...mapGetters({
        editMode: 'getEditMode',
        getNodeWidget: 'getNodeWidget',
        getOneDepartment: 'types/getOneDepartment',
        getOneCategory: 'types/getOneCategory',
        getStyleColors: 'getStyleColors',
        getStylePatterns: 'getStylePatterns',
        getModules: 'opcua/getModules',
        getModule: 'opcua/getModule',
        getNode: 'opcua/getNode',
        getModuleFetched: 'opcua/getModuleFetched',
        getSynoptoCode: 'types/getSynoptoCode',
        isMaster: 'auth/isMaster',
    }),
    value1: function () {
      if (typeof(this.value) === "object") return this.value.value;
      else if (typeof(this.value) === "string") return this.value;
      return '';
    },
    unit1: function () {
      if (typeof(this.value) === "object") {
        if (this.value.unit.includes("#")) return '';
        return this.value.unit;
      }
      return '';
    },
    icon1: function () {
      return "fi fi-rr-home";
    },
    fgColor1: function () {
      if (this.fgColor) {
        return `${this.fgColor}`;
      }
      return '';
    },
    department: function () {
      if (this.moduleData.metadata.department) return this.moduleData.metadata.department;
      else return "";
    },
    label: function () {
      if (this.moduleData.metadata.label) return this.moduleData.metadata.label;
      else return "";
    },
    description: function () {
      if (this.moduleData.metadata.description) return this.moduleData.metadata.description;
      else return "";
    },
    departmentKey: function () {
      return this.moduleDepartment().key;
    },
    departmentLabel: function () {
      return this.moduleDepartment().label;
    },
    isRecord() {
      if (typeof(this.value) === "object") {
        if (this.value.record === "true") return true;
      }
      return false;
    },
    showPointer() {
      if (this.isRecord) return "cursor-pointer";
      else return "";
    }
  },
  mounted() {
  },
  methods: {
    moduleDepartment: function () {
        if (this.moduleData !== null) {
            if (this.moduleData.metadata.department !== null) {
                return this.getOneDepartment(this.moduleData.metadata.department);
            }
        }
        return { key: store.getters.getNoval, label: store.getters.getNoval };
    },
    moduleCategory: function () {
        if (this.moduleData !== null) {
            if (this.moduleData.metadata.category !== null) {
                return this.getOneDepartment(this.moduleData.metadata.category);
            }
        }
        return { key: store.getters.getNoval, label: store.getters.getNoval };
    },
    showChart(value) {
      if (this.isRecord) {
        if (value === 'value1') this.showChart1 = true;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.input-container {
  display: flex;
  position: relative;
  align-items: center;
  color: #f9bf45;
  font-size: 1.2rem;
  background: linear-gradient(45deg, rgba(168, 73, 122, 0.8), rgba(168, 73, 122, 0.7));
  padding: 8px 12px;
  gap: 5px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 0 1px rgba(8, 8, 8, .5) inset,
    0 1px 0 0 rgba(252, 250, 242, .07),
    0 1px 2px 1px rgba(8, 8, 8, .5) inset;
}

.input-container .bash-text {
  font-size: .8rem;
  color: white;
}

.input-container .bash-text .user {
  color: #E879F9;
}

.input-container .bash-text .vm {
  color: #2DD4BF;
}

.input-container .bash-text .char {
  color: #A78BFA;
}

.input-container .bash-text .value {
  background-color: transparent;
  border: none;
  outline: none;
  color: yellow;
}

.input-container .icon-inline {
  position: absolute;
  font-size: 3.4rem;
  right: -20px;
  color: #b481bb;
  opacity: 0.2;
}

.input-container .title {
  margin: 0;
  padding: 0;
  z-index: 1;
}
</style>