<template>
  <Dialog
    v-model:visible="showDialog"
    :breakpoints="{ '960px': '85vw', '640px': '90vw' }"
    :style="{ width: '50vw' }"
    maximizable
    blockScroll
    keepInViewPort
		@hide="hideDiag($event)"
		@show="openendDiag($event)"
  >
    <template #header>
			<h5>
				<i class="fi fi-rr-chart-histogram mr-3"></i>
				<span class="subtext mt-1">
					<Avatar :label="node.department" v-tooltip="`${node.moduleLabel} - ${node.moduleDescription}`" class="mr-1 font-semibold bg-lightLime fg-crimson cursor-pointer" />
					{{ node.moduleLabel }} {{ node.label }} ⚡ {{ node.description }}</span>
					<i :class="'fi ' + icon_1 + ' icon-inline text-2xl ml-1 fg-amber '"></i>
			</h5>
    </template>
    <div v-if="loading" class="flex justify-content-center flex-wrap" style="{height: 400px}">
        <ProgressSpinner
        style="width: 50px; height: 50px"
        strokeWidth="4"
        fill="var(--red-600)"
        animationDuration="1s"
        />
    </div>
    <div v-else>
      <div class="chart-container" :key="chartSeries[0].data">
        <div class="flex justify-content-center align-items-center p-0 flex-wrap">
          <div class="flex justify-content-center align-items-center p-0 mr-2">
            <span class="person-tag fg-amber bg-darkSteel p-1">Minimum: <span class="fg-lightAmber">{{ valueMin }} {{ unit_1 }}</span></span>
          </div>
          <div class="flex justify-content-center align-items-center p-0 mr-2">
            <span class="person-tag fg-amber bg-darkSteel p-1">Maximum: <span class="fg-lightAmber">{{ valueMax }} {{ unit_1 }}</span></span>
          </div>
          <div class="flex justify-content-center align-items-center p-0 mr-2">
            <span class="person-tag fg-amber bg-darkSteel p-1">Mittelwert: <span class="fg-lightAmber">{{ valueAvg }} {{ unit_1 }}</span></span>
          </div>
					<div class="flex justify-content-center align-items-center p-0 mr-2">
            <span class="person-tag fg-amber bg-darkSteel p-1">Aktuell: <span class="fg-lightAmber">{{ node.value }} {{ unit_1 }}</span></span>
          </div>
        </div>

        <VueApexCharts id="chartVal1" type="area" :options="chartOptions" :series="chartSeries" :height="'350px'" />
      </div>
      <div class="flex justify-content-center align-items-center p-0 flex-wrap">
        <div class="flex justify-content-center align-items-center p-0 mr-2">
          <span class="p-float-label">
            <Calendar 
              id="calendar-start"
              v-model="dateTimeSelectStart"
              showTime hourFormat="24"
              dateFormat="dd.mm.yy"
              @update:model-value="changeDate('')"
            />
            <label for="calender-start">Bereich von</label>
          </span>
        </div>
        <i class="mif-arrow-right icon-inline text-2xl mr-2 fg-amber "></i>
        <div class="flex justify-content-center align-items-center p-0">
          <span class="p-float-label">
            <Calendar 
              id="calendar-end"
              v-model="dateTimeSelectEnd"
              showTime hourFormat="24"
              dateFormat="dd.mm.yy"
              @update:model-value="changeDate('')"
            />
            <label for="calender-end">Bereich bis</label>
          </span>
        </div>
      </div>
      <div class="flex justify-content-center align-items-center mt-3 p-0 flex-wrap">
        <i class="fi fi-rr-time-oclock icon-inline text-2xl mr-2 fg-amber "></i>
        <div class="flex justify-content-center align-items-center p-0 mr-2">
            <Button 
              id="calendar-24h"
              label="24h"
              @click="changeDate('24h')"
            />
        </div>
        <div class="flex justify-content-center align-items-center p-0 mr-2">
            <Button 
              id="calendar-48h"
              label="48h"
              @click="changeDate('48h')"
            />
        </div>
        <div class="flex justify-content-center align-items-center p-0 mr-2">
            <Button 
              id="calendar-7d"
              label="7 Tage"
              @click="changeDate('7d')"
            />
        </div>
        <div class="flex justify-content-center align-items-center p-0 mr-2">
            <Button 
              id="calendar-30d"
              label="30 Tage"
              @click="changeDate('30d')"
            />
        </div>
        <div class="flex justify-content-center align-items-center p-0 mr-2">
            <Button 
              id="calendar-2m"
              label="2 Monate"
              @click="changeDate('2m')"
            />
        </div>
        <div class="flex justify-content-center align-items-center p-0 mr-2">
            <Button 
              id="calendar-1y"
              label="1 Jahr"
              @click="changeDate('1y')"
            />
        </div>
        <i class="fi fi-rr-time-oclock icon-inline text-2xl ml-2 fg-amber "></i>
      </div>
    </div>
  </Dialog>
</template>

<script>
import { defineComponent } from "vue";
import { combineRedisHash, parseNode } from "@/helpers";
import VueApexCharts from "vue3-apexcharts";
import SocketioService from '@/services/socketioService.js';

export default defineComponent({
  name: "dialogChartSingle",
  setup() {},
	emits: ['chartClose'],
  components: {
   VueApexCharts,
  },
  props: {
    node: {
      type: null,
      required: true
    },
    icon: {
      type: String,
      required: false
    },
		show: {
				type: Boolean,
				required: true,
		},
  },
  data() {
    return {
      loading: false,
			showDialog: false,
			openedDialog: false,
      openChart1: false,
      valueMin: null,
      valueMax: null,
      valueAvg: null,
      dateTimeSelectStart: null,
      dateTimeSelectEnd: null,
			dateTimeFormat: null,
      chartSeries: [{
        name: `${this.node.label}`,
        connectNulls: true,
        data: []
      }],
      chartOptions: {
        chart: {
          type: 'area',
          stacked: false,
          defaultLocale: 'de',
          locales: [{
            name: 'de',
            options: {
              months: ['Jänner', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
              shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
              days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
              shortDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
              toolbar: {
                download: 'Download SVG',
                selection: 'Auswahl',
                selectionZoom: 'Auswahl Zoom',
                zoomIn: 'Zoom In',
                zoomOut: 'Zoom Out',
                pan: 'Panning',
                reset: 'Reset Zoom',
              }
            }
          }],
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          toolbar: {
            autoSelected: 'zoom',
            export: {
              csv: {
                headerCategory: `Date;Time`,
                columnDelimiter: ';',
                dateFormatter: function(timestamp) {
                  return new Date(timestamp).toLocaleString('de-AT');
                },
              }
            },
          },
          brush: {
            enabled: false,
            target: 'chartVal1',
            autoScaleYaxis: false
          },
        },
        theme: {
          palette: 'palette4',
        },
        colors: ['#ede06e', '#E91E63'],
        stroke: {
          width: 2
        },
        grid: {
          borderColor: "#555",
          clipMarkers: false,
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.8,
            opacityTo: 0,
            stops: [0, 90, 100]
          },
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return val;
            },
          },
          title: {
            text: `${this.node.unitStr}`
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            formatter: function (value, timestamp) {
              return new Date(timestamp).toLocaleString('de-AT');
            },
          }
        },
        tooltip: {
          shared: false,
          theme: 'dark',
          x: {
            show: true,
            formatter: function (value) {
              return new Date(value).toLocaleString('de-AT');
            },
          },
          y: {
            formatter: function (val) {
              return `${val}`;
            }
          },
          items: {
            display: 'flex',
          },
        }
      },
    }
  },
  watch: {
		show() {
			if (!this.showDialog && this.show && !this.openedDialog) {
				this.showDialog = true;
				this.showChart(this.node);
			}
		}
  },
  computed: {
		diagState: function () {
			return this.show;
		},
    unit_1: function () {
      return this.node.unitStr;
    },
    icon_1: function () {
      if (this.icon) {
        return this.icon;
      }
      return this.node.icon;
    },
  },
  mounted() {
		
  },
  methods: {
    showChart(node) {
      if (node) {
        if (!this.openChart1) this.openChart1 = true;
        this.changeDate();
        // if (!this.dateTimeSelectStart) this.dateTimeSelectStart = new Date(Date.now() - (24 * 60 * 60 * 1000));
        // if (!this.dateTimeSelectEnd) this.dateTimeSelectEnd = new Date(Date.now());

      }
    },
    renderChartMongo(node) {
      let dateStart = new Date();
      let dateEnd = new Date();
      dateStart.setTime(this.dateTimeSelectStart);
      dateEnd.setTime(this.dateTimeSelectEnd);
      this.chartOptions.xaxis.min = dateStart;
      this.chartOptions.xaxis.max = dateEnd;

      const request = {
        hash: combineRedisHash(node.server, node.identifier),
        startTime: dateStart,
        endTime: dateEnd,
				format: this.dateTimeFormat
        // startTime: dateDayAgo,
        // endTime: dateNow
      }
      this.loading = true;
      SocketioService.getChart(request, (err, response) => {
        if (!err && response !== null) {
          // this.io_data = [ ...response ];
          console.log(response);
          this.chartOptions.xaxis.categories = [];
          this.chartSeries[0].data = [];
          response.forEach((entry) => {
            const newObj = JSON.parse(JSON.stringify(this.node));
            newObj.value = entry.value;
            const scaledObj = parseNode(newObj);
						if (typeof scaledObj.value !== 'number') scaledObj.value = Number(scaledObj.value);
            this.chartOptions.xaxis.categories.push(entry.timestamp);
            this.chartSeries[0].data.push(scaledObj.value);
          });
          this.valueMin = Math.min(...this.chartSeries[0].data).toFixed(2);
          this.valueMax = Math.max(...this.chartSeries[0].data).toFixed(2);
          this.valueAvg = this.chartSeries[0].data.reduce((acc, v, i, a) => acc + v / a.length, 0).toFixed(2);
          this.loading = false;
        }
      });
    },
    renderChart(node) {
      let dateStart = new Date();
      let dateEnd = new Date();
      dateStart.setTime(this.dateTimeSelectStart);
      dateEnd.setTime(this.dateTimeSelectEnd);
      this.chartOptions.xaxis.min = dateStart;
      this.chartOptions.xaxis.max = dateEnd;

      const request = {
        hash: combineRedisHash(node.server, node.identifier),
        startTime: dateStart,
        endTime: dateEnd,
				format: this.dateTimeFormat
        // startTime: dateDayAgo,
        // endTime: dateNow
      }
      this.loading = true;
      SocketioService.getChart(request, (err, response) => {
        if (!err && response !== null) {
          // this.io_data = [ ...response ];
          console.log(response);
          this.chartOptions.xaxis.categories = [];
          this.chartSeries[0].data = [];
          response.forEach((entry) => {
            const newObj = JSON.parse(JSON.stringify(this.node));
            newObj.value = entry._value;
            if (typeof newObj.value === 'boolean') {
              newObj.unit = "";
              if (newObj.value === true) newObj.value = 1;
              else newObj.value = 0;
            }
            if (newObj.unit === '#HMS') {
              newObj.unit = "";
            }
            // if (typeof newObj.value !== 'number') {
            //   if (typeof newObj.value === 'boolean') {
            //     if (newObj.value === true) newObj.value = 1;
            //     else newObj.value = 0;
            //   } else {
            //     newObj.value = Number(newObj.value);
            //   }
            // }
            const scaledObj = parseNode(newObj);
            
						if (typeof scaledObj.value !== 'number') scaledObj.value = Number(scaledObj.value);
            this.chartOptions.xaxis.categories.push(entry._time);
            this.chartSeries[0].data.push(scaledObj.value);
          });
          this.valueMin = Math.min(...this.chartSeries[0].data).toFixed(2);
          this.valueMax = Math.max(...this.chartSeries[0].data).toFixed(2);
          this.valueAvg = this.chartSeries[0].data.reduce((acc, v, i, a) => acc + v / a.length, 0).toFixed(2);
          this.loading = false;
        }
      });
    },
    changeDate(startDate) {
			this.dateTimeFormat = '%d.%m.%Y - %H:%Mh';
      if (startDate === '24h') {
				this.dateTimeSelectStart = new Date(Date.now() - (24 * 60 * 60 * 1000));
			}
      else if (startDate === '48h') {
				this.dateTimeSelectStart = new Date(Date.now() - (2 * 24 * 60 * 60 * 1000));
			}
      else if (startDate === '7d') {
				this.dateTimeSelectStart = new Date(Date.now() - (7 * 24 * 60 * 60 * 1000));
				this.dateTimeFormat = '%d.%m.%Y - %H:';
			}
      else if (startDate === '30d') {
				this.dateTimeSelectStart = new Date(Date.now() - (30 * 24 * 60 * 60 * 1000));
				this.dateTimeFormat = '%d.%m.%Y - %Hh';
			}
      else if (startDate === '2m') {
				this.dateTimeSelectStart = new Date(Date.now() - (60 * 24 * 60 * 60 * 1000));
				this.dateTimeFormat = '%d.%m.%Y - %Hh';
			}
      else if (startDate === '1y') {
				this.dateTimeSelectStart = new Date(Date.now() - (365 * 24 * 60 * 60 * 1000));
				this.dateTimeFormat = '%d.%m.%Y';
			}

			if (!this.dateTimeSelectEnd) this.dateTimeSelectEnd = new Date(Date.now());
      if (!this.dateTimeSelectStart) this.dateTimeSelectStart = new Date(Date.now() - (24 * 60 * 60 * 1000));
      // this.showChart1(this.value);
      this.renderChart(this.node);
    },
		hideDiag() {
			this.$emit('chartClose');
			this.openedDialog = false;
		},
		openendDiag() {
			this.openedDialog = true;
		}
  },
});
</script>

<style lang="scss" scoped>
.apexcharts-tooltip {
  color: #000000;
}
.apexcharts-tooltip .apexcharts-tooltip-series-group.active {
  background: red !important;
}
</style>