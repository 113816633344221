<template>
  <div class="input-container">
    <i v-if="icon1" :class="icon1" class="icon-inline"></i>
    <p class="bash-text">
      <span class="vm">»</span><span class="char">{{ title1 }}</span>: <span class="value">{{ value1 }}</span>
      <!-- <span class="user">[{{ department }}]</span><span class="vm"> {{ label }}</span> > <span class="char">{{ title1 }}</span>: <span class="value">{{ value1 }}</span> -->
    </p>
  </div>
  <!-- <button v-if="badge1" class="widgetButton" :class="`${fgColor1} ${bgColor1}`" v-tooltip="title1" v-badge="badge1">
    <div class="widgetButton_lg" :class="``"></div>
    <span class="button_text"><i v-if="icon1" :class="icon1" class="icon-inline fs-large mr-1"></i>{{ title1 }}</span>
  </button>
  <button v-else class="widgetButton" :class="`${fgColor1} ${bgColor1}`" v-tooltip="title1">
    <div class="widgetButton_lg" :class="``"></div>
    <span class="button_text"><i v-if="icon1" :class="icon1" class="icon-inline fs-large mr-1"></i>{{ title1 }}</span>
  </button> -->
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "textboxEnat",
  setup() {},
  components: {
  },
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    moduleData: {
      type: Object,
      required: true
    },
    icon: {
      type: String,
      required: false
    },
    fgColor: {
      type: String,
      required: false
    },
    bgColor: {
      type: null,
      required: false
    },
    badge: {
      type: null,
      required: false
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  watch: {

  },
  computed: {
    title1: function () {
      if (this.title) return this.title;
      return '';
    },
    value1: function () {
      if (this.value) return this.value;
      return '';
    },
    subtitle1: function () {
      if (this.subtitle) return this.subtitle;
      return '';
    },
    icon1: function () {
      if (this.icon) {
        return this.icon;
      }
      return '';
    },
    fgColor1: function () {
      if (this.fgColor) {
        return `fg-${this.fgColor}`;
      }
      return '';
    },
    bgColor1: function () {
      if (this.bgColor) {
        return `bg-${this.bgColor}`;
      }
      return '';
    },
    badge1: function () {
      if (this.badge) {
        return this.badge.toString();
      }
      return null;
    },
    department: function () {
      if (this.moduleData.metadata.department) return this.moduleData.metadata.department;
      else return "";
    },
    label: function () {
      if (this.moduleData.metadata.label) return this.moduleData.metadata.label;
      else return "";
    },
    description: function () {
      if (this.moduleData.metadata.description) return this.moduleData.metadata.description;
      else return "";
    }
  },
  mounted() {
  },
  methods: {
  },
});
</script>

<style lang="scss" scoped>
.input-container {
  font-family: "SystemFont", Helvetica, Arial, sans-serif;
  display: flex;
  position: relative;
  align-items: center;
  // background-color: #0F172A;
  // background: linear-gradient(45deg, #0F172A, #192747);
  background: linear-gradient(45deg, rgba(15, 23, 42, 0.5), rgba(25, 39, 71, 0.5));
  padding: 8px 15px;
  gap: 5px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 0 0 1px rgba(8, 8, 8, .5) inset,
    0 1px 0 0 rgba(252, 250, 242, .07),
    0 1px 2px 1px rgba(8, 8, 8, .5) inset;
}

.input-container .bash-text {
  font-size: .8rem;
  color: white;
}

.input-container .bash-text .user {
  color: #E879F9;
}

.input-container .bash-text .vm {
  color: #2DD4BF;
}

.input-container .bash-text .char {
  color: #A78BFA;
}

.input-container .bash-text .value {
  background-color: transparent;
  border: none;
  outline: none;
  color: yellow;
}

.input-container .icon-inline {
  margin: 1px 0 0 1px;
  position: absolute;
  right: 10px;
  top: -5px;
  font-size: 4rem;
  transform: rotate(30deg);
  color: yellow;
  opacity: 0.2;
}
</style>