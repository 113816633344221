<template>
    <Dialog v-model:visible="entryDialog" :style="{ width: '450px' }" :header="'Ventil bearbeiten [' + ioDialogData.i + ']'" :modal="true" class="p-fluid" @hide="hideDialog">
        <div class="formgrid grid justify-content-center">
            <div class="field">
                <p>Gruppenzuordnung mit Ventil-Laufzeiten</p>
                <div class="grid">
                    <div class="field-checkbox col">
                        <Checkbox id="grp1" name="vavleGroup" value="Chicago" v-model="valveGroup" />
                        <label for="grp1">Gruppe 1 [BEW]</label>
                    </div>
                    <div class="field col">
                        <InputMask mask="99:99:99" placeholder="00:00:00" slotChar="hh:mm:ss" @blur="showEvent"/>
                    </div>
                </div>
                <div class="grid">
                    <div class="field-checkbox col">
                        <Checkbox id="grp2" name="valveGroup" value="Los Angeles" v-model="valveGroup" />
                        <label for="grp1">Gruppe 2 [SPÜ]</label>
                    </div>
                    <div class="field col">
                        <InputMask mask="99:99:99" placeholder="00:00:00" slotChar="hh:mm:ss" />
                    </div>
                </div>
                <div class="grid">
                    <div class="field-checkbox col">
                        <Checkbox id="grp3" name="valveGroup" value="New York" v-model="valveGroup" />
                        <label for="grp1">Gruppe 3 [DÜN]</label>
                    </div>
                    <div class="field col">
                        <InputMask mask="99:99:99" placeholder="00:00:00" slotChar="hh:mm:ss" />
                    </div>
                </div>
                <div class="grid">
                    <div class="field-checkbox col">
                        <Checkbox id="gpr4" name="valveGroup" value="San Francisco" v-model="valveGroup" />
                        <label for="grp1">Gruppe 4 [SPE]</label>
                    </div>
                    <div class="field col">
                        <InputMask mask="99:99:99" placeholder="00:00:00" slotChar="hh:mm:ss" />
                    </div>
                </div>
            </div>
        </div>

        <div class="field">
            <label>Ventil Literleistung [l/min]</label>
            <InputMask mask="999" placeholder="000" />
        </div>

        <div class="field">
            <label>ID</label>
            <InputText id="_id" v-model="ioDialogData.data.id" readonly />
        </div>

        <template #footer>
            <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
            <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveDialog" />
        </template>
    </Dialog>
</template>

<script>
import { defineComponent } from 'vue';
import store from '@/store';

export default defineComponent({
    name: 'dialogValve',
    setup() {},
    emits: ['entrySave', 'entryAbort'],
    props: {
        showDialog: {
            type: Boolean,
            required: true,
        },
        dialogData: {
            type: Object,
            required: true,
        },
        dialogObj: {
            type: Object,
            required: false,
        },
    },
    mounted() {
        this.ioDialogData = this.dialogData;
        this.entryDialog = this.entryDiag;
        for (let node of this.nodes) {
            if (node.key === this.ioObj.metadata.category) {
                this.expandNode(node);
            }
        }
        console.log(this.ioDialogData);
    },
    computed: {
        // ioDialogData: function () {
        //   return this.dialogData;
        // },
        ioObj: function () {
            return this.dialogObj;
        },
        entryDiag: function () {
            return this.showDialog;
        },
        // ioDialogData: function () {
        //   return this.dialogData;
        // },
        nodeEntry: function () {
            if (this.content.data.id) {
                return store.getters.getValue(this.content.data.id);
            } else return undefined;
        },
        nodes: function () {
            return store.getters.getIoTree;
        },
        colors: function () {
            return store.getters.getStyleColors;
        },
        patterns: function () {
            return store.getters.getStylePatterns;
        },
    },
    data() {
        return {
            loading: true,
            entryDialog: false,
            ioDialogData: {},
            // ioObj: {},
            submitted: false,
            selectedKey: {},
            expandedKeys: {},
            valveGroup: [],
        };
    },
    methods: {
        showEvent(e) {
            console.log(e.originalTarget.value);
        },
        hideDialog() {
            this.$emit('entryAbort');
        },
        resetDialogData() {
            this.ioDialogData = {
                style: {},
                components: {},
                data: {},
            };
            this.selectedKey = {};
            this.expandedKeys = {};
        },
        expandNode(node) {
            this.expandedKeys[node.key] = true;
            if (node.children && node.children.length) {
                for (let child of node.children) {
                    this.expandNode(child);
                    if (child.key === this.ioObj._id) {
                        this.selectedKey[this.ioObj._id] = true;
                    }
                }
            }
        },
        saveDialog() {
            this.submitted = true;
            console.log(this.ioDialogData);

            if (this.ioDialogData.i) {
                // let _index = this.findIndexById(this.ioDialogData._id);
                // if (_index >= 0) {
                //   this.system_user[_index] = this.ioDialogData;
                //   // SocketioService.setUserList(this.system_user[_index]);
                // } else {
                //   // SocketioService.setUserList(this.ioDialogData);
                // }
                // this.content = {...this.ioDialogData};
                this.$emit('entrySave', this.ioDialogData);
                this.entryDialog = false;
                this.resetDialogData();
            }
        },
        onNodeSelect(node) {
            const newObj = store.getters.getValue(node.key);
            this.selectedKey = node.key;
            this.ioDialogData.data.id = node.key;

            this.$toast.add({
                severity: 'success',
                summary: 'Node ausgewählt',
                detail: newObj.metadata.name + '\n' + this.selectedKey + '\n' + newObj.nodeId,
                life: 3000,
            });
        },
    },
});
</script>
