<template>
    <Dialog header="Header" v-model:visible="entryDialog" :style="{width: '50vw'}" :maximizable="true" :modal="false" :resizeable="true">
            <!-- <p class="m-0">{{ ioDialogData }}</p> -->
            <div class="charti">
                <apexchart width="100%" height="500px" type="line" :options="options" :series="series"></apexchart>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="hideDialog" class="p-button-text"/>
                <Button label="Yes" icon="pi pi-check" @click="hideDialog" autofocus />
            </template>
        </Dialog>
</template>

<script>
import { defineComponent } from 'vue';
import store from '@/store';
import SocketioService from '@/services/socketioService.js';

export default defineComponent({
    name: 'dialogChart',
    setup() {},
    emits: ['entrySave', 'entryAbort'],
    props: {
        showDialog: {
            type: Boolean,
            required: true,
        },
        dialogData: {
            type: Object,
            required: true,
        },
        dialogObj: {
            type: Object,
            required: false,
        },
    },
    mounted() {
        this.ioDialogData = this.dialogData;
        this.entryDialog = this.entryDiag;
        // for (let node of this.nodes) {
        //     if (node.key === this.ioObj.metadata.category) {
        //         this.expandNode(node);
        //     }
        // }
        console.log(this.ioDialogData);
        this.getChart();
    },
    computed: {
        // ioDialogData: function () {
        //   return this.dialogData;
        // },
        ioObj: function () {
            return this.dialogObj;
        },
        entryDiag: function () {
            return this.showDialog;
        },
        // ioDialogData: function () {
        //   return this.dialogData;
        // },
        nodeEntry: function () {
            if (this.ioDialogData.data.id) {
                return store.getters.getValue(this.ioDialogData.data.id);
            } else return undefined;
        },
        nodes: function () {
            return store.getters.getIoTree;
        },
        colors: function () {
            return store.getters.getStyleColors;
        },
        patterns: function () {
            return store.getters.getStylePatterns;
        },
    },
    data() {
        return {
            loading: true,
            entryDialog: false,
            ioDialogData: {},
            // ioObj: {},
            submitted: false,
            selectedKey: {},
            expandedKeys: {},
            valveGroup: [],
            chart24Loading: true,
            chart24Timestamp: null,
            options: {
                chart: {
                    id: 'daily',
                    offsetX: 0,
                    toolbar: {
                        autoSelected: "pan",
                        show: true
                    },
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350
                        }
                    },
                    zoom: {
                        enabled: true,
                    },
                },
                colors: ['rgba(255, 255, 0, 0.9)'],
                stroke: {
                    width: 2,
                    curve: 'smooth',
                },
                grid: {
                    show: true,
                    borderColor: ['rgba(255, 255, 0, 0.1)'],
                    clipMarkers: false,
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 10
                    },
                    yaxis: {
                        lines: {
                            show: true
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                fill: {
                    gradient: {
                        enabled: false,
                        opacityFrom: 0.9,
                        opacityTo: 0.9
                    }
                },
                markers: {
                    size: 2,
                    colors: ["#CBFF31"],
                    strokeColor: "#CBFF31",
                    strokeWidth: 1
                },
                tooltip: {
                    enabled: true,
                    theme: "dark",
                    style: {
                        fontSize: '9px',
                    },
                },
                responsive: [
                    {
                    breakpoint: 1000,
                    options: {
                        plotOptions: {
                        bar: {
                            horizontal: false
                        }
                        },
                        legend: {
                            position: "bottom"
                        }
                    }
                    }
                ],
                xaxis: {
                    show: false,
                    categories: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
                    labels: {
                        show: true,
                        align: 'right',
                        minWidth: 0,
                        maxWidth: 30,
                        offsetX: -10,
                        style: {
                            fontSize: '8px'
                        }
                    },
                    axisBorder: {
                        show: true,
                    },
                    axisTicks: {
                        show: true,
                    },
                    tooltip: {
                        enabled: false,
                        formatter: undefined,
                        offsetY: 0,
                        style: {
                            fontSize: 10,
                            fontFamily: 0,
                        },
                    },
                    crosshairs: {
                        show: true,
                        width: 1,
                        position: 'back',
                        opacity: 0.9,        
                        stroke: {
                            color: '#b6b6b6',
                            width: 1,
                            dashArray: 0,
                        },
                        fill: {
                            type: 'solid',
                            color: '#B1B9C4',
                            gradient: {
                                colorFrom: '#D8E3F0',
                                colorTo: '#BED1E6',
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            },
                        },
                    },
                    
                },
                yaxis: {
                    show: true,
                    opposite: false,
                    labels: {
                        show: true,
                        align: 'right',
                        minWidth: 0,
                        maxWidth: 30,
                        offsetX: -10,
                        style: {
                            fontSize: '8px'
                        }
                    },
                    axisBorder: {
                        show: true,
                        color: '#78909C',
                        offsetX: 0,
                        offsetY: 0
                    },
                    axisTicks: {
                        show: true,
                        borderType: 'solid',
                        color: '#78909C',
                        width: 6,
                        offsetX: 0,
                        offsetY: 0
                    },
                }
            },
            options2: null,
            series: [
                {
                    name: '1-Tagesansicht',
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                }
            ],
        };
    },
    methods: {
        showEvent(e) {
            console.log(e.originalTarget.value);
        },
        hideDialog() {
            this.$emit('entryAbort');
        },
        resetDialogData() {
            this.ioDialogData = {
                style: {},
                components: {},
                data: {},
            };
            this.selectedKey = {};
            this.expandedKeys = {};
        },
        expandNode(node) {
            this.expandedKeys[node.key] = true;
            if (node.children && node.children.length) {
                for (let child of node.children) {
                    this.expandNode(child);
                    if (child.key === this.ioObj._id) {
                        this.selectedKey[this.ioObj._id] = true;
                    }
                }
            }
        },
        saveDialog() {
            this.submitted = true;
            console.log(this.ioDialogData);

            if (this.ioDialogData.i) {
                // let _index = this.findIndexById(this.ioDialogData._id);
                // if (_index >= 0) {
                //   this.system_user[_index] = this.ioDialogData;
                //   // SocketioService.setUserList(this.system_user[_index]);
                // } else {
                //   // SocketioService.setUserList(this.ioDialogData);
                // }
                // this.content = {...this.ioDialogData};
                this.$emit('entrySave', this.ioDialogData);
                this.entryDialog = false;
                this.resetDialogData();
            }
        },
        onNodeSelect(node) {
            const newObj = store.getters.getValue(node.key);
            this.selectedKey = node.key;
            this.ioDialogData.data.id = node.key;

            this.$toast.add({
                severity: 'success',
                summary: 'Node ausgewählt',
                detail: newObj.metadata.name + '\n' + this.selectedKey + '\n' + newObj.nodeId,
                life: 3000,
            });
        },
        getChartMongo() {
            this.chart24Loading = true;
            this.chart24Timestamp = new Date(Date.now());
            // for (let i = 0; i < 10; i++) {
            //     this.storeAData.datasets[0].data[i] = this.nodeEntry.value.value;
            // }
            if (this.ioDialogData !== undefined) {
                SocketioService.getServerData('getChart', this.nodeEntry.source.path, (err, response) => {
                    if(response !== null) {
                        let values24 = [];
                        let labels24 = [];
                        for (let i = 0; i < response.length; i++) {
                        values24.push(response[i].value);
                        this.options.xaxis.categories[i] = response[i]._id;
                        labels24.push(response[i]._id);
                        this.series[0].data[i] = response[i].value;
                        //   this.$refs.storeB.chart.update();
                        }
                        // if (this.$refs.storeB !== null) {
                        //     if (this.$refs.storeB.chart !== undefined) {
                        //         this.$refs.storeB.chart.update();
                        //     }
                        // }
                        this.valueMin = Math.min(...values24).toFixed(2);
                        this.valueMax = Math.max(...values24).toFixed(2);
                        this.valueAvg = values24.reduce((acc, v, i, a) => acc + v / a.length, 0).toFixed(2);
                        this.chart24Loading = false;
                    }
                });
            }
        },
        getChart() {
            this.chart24Loading = true;
            this.chart24Timestamp = new Date(Date.now());
            // for (let i = 0; i < 10; i++) {
            //     this.storeAData.datasets[0].data[i] = this.nodeEntry.value.value;
            // }
            if (this.ioDialogData !== undefined) {
                SocketioService.getServerData('getChart', this.nodeEntry.source.path, (err, response) => {
                    if(response !== null) {
                        let values24 = [];
                        let labels24 = [];
                        for (let i = 0; i < response.length; i++) {
                            values24.push(response[i]._value);
                            this.options.xaxis.categories[i] = response[i]._measurement;
                            labels24.push(response[i]._measurement);
                            this.series[0].data[i] = response[i]._value;
                            //   this.$refs.storeB.chart.update();
                        }
                        // if (this.$refs.storeB !== null) {
                        //     if (this.$refs.storeB.chart !== undefined) {
                        //         this.$refs.storeB.chart.update();
                        //     }
                        // }
                        this.valueMin = Math.min(...values24).toFixed(2);
                        this.valueMax = Math.max(...values24).toFixed(2);
                        this.valueAvg = values24.reduce((acc, v, i, a) => acc + v / a.length, 0).toFixed(2);
                        this.chart24Loading = false;
                    }
                });
            }
        },
    },
});
</script>
