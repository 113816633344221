<template>
  <div>
    <Dialog
      v-model:visible="entryDialog"
      :style="{ width: '450px' }"
      header="Parameter für Gruppe auswählen"
      :modal="true"
      class="p-fluid"
      @hide="hideDialog"
    >
      <div class="field col">
        <div class="field text-center">
          <span :class="ioDialogData.icon + ' mif-5x'"></span>
        </div>
      </div>
      <div class="field">
        <label for="categoryStatus" class="mb-3">Kategorie</label>
        <Dropdown
          id="categoryStatus"
          v-if="config_io !== null"
          v-model="ioDialogData.key"
          :options="config_io.types_category"
          optionLabel="label"
          optionValue="value"
          placeholder="Wetterstation, Heizung,..."
          @change="onCategoryChange"
        >
        </Dropdown>
      </div>
      <div class="field">
        <Tree :value="io_subtype" selectionMode="single" v-model:selectionKeys="selectedParameter" :expandedKeys="expandedKeys" @nodeSelect="onNodeSelect"></Tree>
      </div>
      <template #footer>
        <Button
          label="Abbrechen"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideDialog"
        />
        <Button
          label="Speichern"
          icon="pi pi-check"
          class="p-button-text"
          @click="saveIOsss"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import store from '@/store';
import helpers from "@/helpers";
import { FilterMatchMode } from "primevue/api";
// import ProductService from "../helpers";
// import mainService from "@/services/mainService.js";
import SocketioService from "@/services/socketioService.js";
// import socketioService from "@/services/socketioService.js";

export default defineComponent({
  name: 'dialogParameter',
  setup() {},
  emits: ['entrySave', 'entryAbort'],
  props: {
      showDialog: {
          type: Boolean,
          required: true,
      },
      dialogData: {
          type: Object,
          required: true,
      },
      dialogObj: {
          type: Object,
          required: false,
      },
  },
  data() {
    return {
      entryDialog: false,
      loading: false,
      ioDialog: false,
      ioDialogData: {},
      config_io: null,
      io_type: null,
      io_subtype: [],
      selectedIOs: null,
      selectedParameter: null,
      selectedKey: null,
      expandedKeys: {},
      filters: {},
      submitted: false,
      intval_Value: null,
      elementIndex: -1,
      subtypeIndex: -1,
      subtypeCnt: 0,
    };
  },
  created() {
    this.initFilters();
  },
  mounted() {
    console.log('dialogObj: ');
    console.log(this.dialogObj);
    console.log('dialogData: ');
    console.log(this.dialogData);
    // this.productService.getProducts().then((data) => (this.products = data));
    this.ioDialogData = this.dialogData;
    this.entryDialog = this.entryDiag;
    this.getParameter();
  },
  beforeUnmount() {
    this.unregisterSocketIoEvents();
  },
  computed: {
      // ioDialogData: function () {
      //   return this.dialogData;
      // },
      ioObj: function () {
          return this.dialogObj;
      },
      entryDiag: function () {
          return this.showDialog;
      },
      // ioDialogData: function () {
      //   return this.dialogData;
      // },
      nodeEntry: function () {
          if (this.content.data.id) {
              return store.getters.getValue(this.content.data.id);
          } else return undefined;
      },
      nodes: function () {
          return store.getters.getIoTree;
      },
      colors: function () {
          return store.getters.getStyleColors;
      },
      patterns: function () {
          return store.getters.getStylePatterns;
      },
  },
  methods: {
    expandNode(node) {
        this.expandedKeys[node.key] = true;
        if (node.children && node.children.length) {
            for (let child of node.children) {
                this.expandNode(child);
            }
        }
    },
    expandAll() {
        for (let node of this.io_subtype) {
            this.expandNode(node);
        }

        this.expandedKeys = {...this.expandedKeys};
    },
    onNodeSelect(node) {
      // for (let [key] of Object.entries(this.selectedIOs)) {
      //     if (key.match(/^\d+/)) {
      //         let dataId = {};
      //         dataId.id = key;
      //         // this.ioDialogData.data.push(dataId);
      //         console.log(key, value);
      //     }
      // }
      this.selectedKey = node;
      console.log(this.selectedKey);
    },
    onCategoryChange(category) {
      const _cat = helpers.findIndexByName(this.config_io.types_category, category.value);
      console.log(this.config_io.types_category[_cat]);
      this.ioDialogData.label = this.config_io.types_category[_cat].label;
      this.ioDialogData.data = this.config_io.types_category[_cat].value;
      this.getChildren(this.config_io.types_category[_cat].value);
    },
    resetDialogData() {
      this.ioDialogData = {
        // group: {},
      };
      this.elementIndex = -1;
      this.selectedParameter = null;
      this.selectedKey = null;
      this.io_subtype = [];
      this.subtypeCnt = 0;
    },
    getParameterConfigIO() {
      SocketioService.getServerData("config", 'io', (err, response) => {
        this.config_io = response;
        this.io_type = response.types_parameters;
        // console.log(this.io_type);
        // this.io_type.sort((a, b) => a.label.localeCompare(b.label));
        this.loading = false;
      });
      // SocketioService.getConfigIO((response) => {
      //   this.config_io = response;
      //   this.io_type = response.types_icons;
      //   this.loading = false;
      //   // this.io_department = response.types_department;
      //   // this.io_types = response.types_io;
      //   // this.io_unit = response.types_unit;
      //   // this.io_server = response.types_server;
      //   // this.io_icons = response.types_icons;
      // });
    },
    getParameter() {
      this.getParameterConfigIO();
    },
    openNew(ioData) {
      this.resetDialogData();

      if (ioData !== null) {
        const _obj = JSON.parse(JSON.stringify(ioData));
        this.ioDialogData = _obj;
      } else {
        this.ioDialogData.label = "Lüftung";
        this.ioDialogData.key = "ventilation";
        this.ioDialogData.data = this.ioDialogData.key;
        this.ioDialogData.icon = "mif-meter";
      }
      this.submitted = false;
      this.ioDialog = true;
      this.expandAll();
    },
    openNewParameter(ioData) {
      if (ioData !== null) {
        const _obj = JSON.parse(JSON.stringify(ioData));
        _obj.key = _obj.key + this.io_subtype[0].children.length;
        this.io_subtype[0].children.push(_obj);
      } else {
        let subtype = {};
        this.subtypeCnt++;
        subtype.label = "# Neuer Parameter " + this.subtypeCnt;
        subtype.key = "VARIABLE_" + this.subtypeCnt;
        subtype.data = this.ioDialogData.key;
        subtype.type = "setpoint"
        subtype.icon = null;
        subtype.unit = "°C";
        subtype.factor = 1;
        if (this.io_subtype.length === 0) {
          this.io_subtype[0] = {};
          this.io_subtype[0].children = [];
        }
        this.io_subtype[0].children.push(subtype);
        console.log(subtype);
      }
      this.submitted = false;
      this.ioDialog = true;
      this.expandAll();
    },
    deleteParameter() {
      console.log(this.selectedKey);
      if (this.selectedKey && this.selectedKey.key !== null) {
        const index = helpers.findIndexByTag(this.io_subtype[0].children, 'key', this.selectedKey.key);
        console.log(index);
        console.log(this.io_subtype[0].children[index]);
        if (index > 0) {
          this.io_subtype[0].children.splice(index, 1);
        }
      }
    },
    hideDialog() {
      this.io_subtype = [];
      this.selectedParameter = null;
      this.selectedKey = null;
      this.ioDialog = false;
      this.submitted = false;
      // clearInterval(this.intval_Value);
      this.getParameter();
    },
    saveIO() {
      this.submitted = true;

      if (this.ioDialogData.key) {
        // this.ioDialogData.icon = this.ioDialogData.label;
        // let _index = helpers.findIndexByName(this.io_type, this.ioDialogData.value);
        if (this.elementIndex >= 0) {
          this.ioDialogData.children = this.io_subtype[0].children;
          this.io_type[this.elementIndex] = this.ioDialogData;
          SocketioService.setConfigIOType(this.io_type, "types_parameters");
        } else {
          let _size = Object.keys(this.io_type).length;
          this.ioDialogData.children = this.io_subtype[0].children;
          this.io_type[_size] = this.ioDialogData;
          // console.log(this.io_type);
          SocketioService.setConfigIOType(this.io_type, "types_parameters");
        }
        // clearInterval(this.intval_Value);
        this.ioDialog = false;
        this.entryDialog = false;
        this.resetDialogData();
      }
    },
    getChildren(category) {
      this.io_subtype = [];
      this.elementIndex = helpers.findIndexByTag(this.io_type, 'key', category);
      console.log(this.io_type);
      console.log(category);
      console.log(this.elementIndex);
      if (this.elementIndex >= 0) {
        this.io_subtype[0] = {};
        this.io_subtype[0].label = this.io_type[this.elementIndex].label;
        this.ioDialogData[0].key = this.io_type[this.elementIndex].value;
        this.ioDialogData[0].children = [];
        this.io_subtype[0].children.push(this.io_type[this.elementIndex]);
      }
      console.log(this.io_subtype);
    },
    editIO(ioData) {
      // this.ioDialogData = { ...ioData };
      this.getParameter();
      const _obj = JSON.parse(JSON.stringify(ioData));
      this.elementIndex = helpers.findIndexByTag(this.io_type, 'key', _obj.key);
      // this.io_subtype = [...this.io_type[this.elementIndex]];
      if (this.elementIndex >= 0) {
        this.io_subtype.push(this.io_type[this.elementIndex]);
      }
      this.subtypeCnt = this.io_subtype[0].children.length;
      // console.log(this.io_subtype);
      // this.elementIndex = helpers.findIndexByName(this.io_type, _obj.value);
      this.ioDialogData = _obj;
      this.ioDialog = true;
      this.expandAll();
    },
    confirmDeleteIO(ioData) {
      const _obj = JSON.parse(JSON.stringify(ioData));
      this.ioDialogData = _obj;
      this.deleteIoDialog = true;
    },
    deleteIO() {
      let _index = helpers.findIndexByName(
        this.io_type,
        this.ioDialogData.value
      );
      if (_index >= 0) {
        this.io_type.splice(_index, 1);
        SocketioService.setConfigIOType(this.io_type, "types_icons");
        // SocketioService.removeUser(this.system_user[_index]);
        // this.$toast.add({
        //   severity: "success",
        //   summary: "Successful",
        //   detail: "Product Updated",
        //   life: 3000,
        // });
      } else {
        // this.product.id = this.createId();
        // this.product.code = this.createId();
        // this.product.image = "product-placeholder.svg";
        // this.products.push(this.product);
        // this.$toast.add({
        //   severity: "success",
        //   summary: "Successful",
        //   detail: "Product Created",
        //   life: 3000,
        // });
      }
      this.deleteIoDialog = false;
      this.resetDialogData();
      // this.$toast.add({
      //   severity: "success",
      //   summary: "Successful",
      //   detail: "Product Deleted",
      //   life: 3000,
      // });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
});
</script>

<style lang="scss" scoped>
</style>