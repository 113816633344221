<template>
  <div class="flex flex-column mr-2">
      <div :class="`flex flex-column mr-2 ${showPointer}`" @click="showChart('value1')">
          <div class="social-icon">
              <i :class="`${iconSymbol} ${colorSymbol} text-4xl`"></i>
          </div>
          <span :class="`text-sm ${colorTextLabel}`">{{ iconLabel }}</span>
      </div>
  </div>
  <dialog-chart-single :icon="iconSymbol" :node="value" v-bind:show="showChart1" @chart-close="showChart1 = false"></dialog-chart-single>
</template>

<script>
import { defineComponent } from "vue";
import dialogChartSingle from '@/components/dialog/chartSingle.vue';

export default defineComponent({
  name: "widgetMiniIcon",
  setup() {},
  components: {
    dialogChartSingle,
  },
  props: {
    label: {
      type: String,
      required: false
    },
    value: {
      type: Object,
      required: true
    },
    icon: {
      type: String,
      required: false
    },
    colorActive: {
      type: String,
      required: false
    },
    colorInactive: {
      type: String,
      required: false
    },
    colorText: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      loading: false,
      showChart1: false,
    }
  },
  watch: {

  },
  computed: {
    iconLabel: function () {
      if (this.label !== undefined) return this.label;
      if (this.value.label !== undefined) return this.value.label;
      return '';
    },
    iconSymbol: function () {
      if (this.icon !== undefined) return this.icon;
      else return this.value.icon;
    },
    colorSymbol() {
      if (this.value.value) {
        if (this.colorActive !== undefined) return this.colorActive;
        else return `fg-lightGreen`;
      } else {
        if (this.colorInactive !== undefined) return this.colorInactive;
        else return `fg-darkGray`;
      }
    },
    colorTextLabel() {
      if (this.colorText !== undefined) return this.colorText;
      else return `fg-gray`;
    },
    isRecord() {
      if (typeof(this.value) === "object") {
        if (this.value.record === "true") return true;
      }
      return false;
    },
    showPointer() {
      if (this.isRecord) return "cursor-pointer";
      else return "";
    }
  },
  mounted() {
  },
  methods: {
    showChart(value) {
      if (this.isRecord) {
        if (value === 'value1') this.showChart1 = true;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
</style>